.markdown {
    .heading {
        color: white;
    }
    color: white;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgb(0 0 0/40%) 0 0);
    border: 2px solid white;
    border-radius: 15px;
    padding: 15px;
    max-width: 500px;
    max-height: 500px;
    overflow-x: auto;
}