body {
  display: flex;
  flex: 1;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
    to right top,
    #1c94be,
    #108cb0,
    #0584a3,
    #007c96,
    #007489,
    #00697c,
    #015e6f,
    #015462,
    #024452,
    #023642,
    #022732,
    #001a23
  );
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    overscroll-behavior: none;
}

/* width */
::-webkit-scrollbar {
  height: 12px;
  position: absolute;
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
  margin: 10px 0px;
}
::-webkit-scrollbar-thumb {
  background: white;
}

::-webkit-scrollbar-thumb:hover {
  background: white; // No variable to deal with this colour, refer to James
}

::-webkit-scrollbar-corner {
  background-color: white;
  border-radius: 0 0 15px 0;
}

@media only screen and (max-width: 1080px) {
  body {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 3px;
    }
  }
}
