.header {
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-bottom: 50px;
    h1 {
        color: white;
        margin: 0px;
    }
    span {
        color: white;
        font-size: medium;
    }
}

.lonelyAi {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: auto;

    .solus {
        min-width: 500px;
        max-width: 50vh;
    }

    .emotions {
      display: flex;
      flex: 1;
      margin: -40px auto;
      flex-direction: column;

      .grid {
        display: flex;
        flex: 1;
        justify-content: center;
      }
    }

    .emotionInfo {
      display: flex;
    }
  }

  @media only screen and (max-width: 1080px) {
    .lonelyAi {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: auto;
        max-width: 100vw;
        min-width: 100vw;
  }

  .solus {
    display: flex;
    margin: auto;
  }

  .latestThought {
    display: flex;
    margin: auto;
  }
}
