.grid {
    display: flex;
    flex-direction: column;
    display: flex;

    .headers {
        color: white;
        font-size: 12px;
        display: flex;
        flex-direction: row;
        flex: 1;

        .header {
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;
            margin: 10px;
        }
    }

    .row {
        display: flex;
        flex-direction: row;
        flex: 1;
    }
}